

























































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import {
  DecoContestVotableEntry,
  DecoModAction,
} from '@/game/infos/dataServerPackets';
import userx from '@/store/modules/userx';
import fb from '@/store/sf-firestore';
import DecoEntryForMod from './DecoEntryForMod.vue';
import globalx from '@/store/modules/globalx';

@Component({
  components: {
    DecoEntryForMod,
  },
})
export default class DecoVote extends Vue {
	public get contestOrdinal() {
		return globalx.decoContestOrdinal;
	}
  public options: Array<{ text: string; value: DecoModAction | '' }> = [
    // { text: 'sort by Sum', value: 's' },
    { text: 'sort by Likes', value: 'l' },
    // { text: 'sort by Dislikes', value: 'd' },
    { text: 'sort by Reports', value: 'r' },
    { text: 'Disqualified', value: 'q' },
    { text: 'Cleared by mod', value: 'c' },
    { text: 'Find with Player Id', value: '' },
  ];
  public entries: DecoContestVotableEntry[] = [];
  public filter: DecoModAction | '' = 'l';
  public playerId = '';

  public mounted() {
    this.loadEntries();
  }

  public async loadEntries() {
    this.entries = (
      await fb.getDecoEntriesForMod(globalx.decoContest, 100, this.filter || this.playerId)
    );
  }
}
